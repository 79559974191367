/*
 * TODO: Relocate this file
 */
import { tap } from 'rxjs/operators';
import { container } from 'src/StaticContainer';
import { client } from 'src/lib/table';

const logger = container.get('Logger');

client.objectDefinitionResult$.pipe(
    tap((res) => {
        if (!res.success) {
            logger.error({ message: res.comment });
        }
    }),
);
