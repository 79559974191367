import qs from 'qs';
import z from 'zod';

/*
 * A note on schemas in this file:
 *
 * Create a schema for _each_ possible query string parameter. One failure to parse shouldn't result in the entire query
 * string being ignored.
 */

const EnvOverrideQuerySchema = z.object({
    featureFlags: z
        .object({
            envOverride: z.enum(['prod', 'prod-alpha', 'dev', 'qa', 'uat']).optional(),
        })
        .optional(),
});

export const getEnvOverride = (): string | undefined => {
    try {
        if (APP_ELECTRON) {
            // Native has no ability to use query string override at this time
            return undefined;
        }

        const query = EnvOverrideQuerySchema.parse(
            qs.parse(location.search, { ignoreQueryPrefix: true, plainObjects: true }),
        );

        const override = query.featureFlags?.envOverride;

        return override;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error parsing query string for env override', e);
        return undefined;
    }
};
