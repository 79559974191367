import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { TypedContainerModule } from '@thinkalpha/common/ioc/TypedContainer.js';
import type { LDEvaluationDetail } from 'launchdarkly-js-sdk-common';
import type { UserDetails } from 'src/store/types';
import type { StaticBindings } from 'src/types/bindings';

const MASKED_KEYS = ['password', 'token', 'accessToken', 'refreshToken', 'masqAccessToken', 'masqToken'];

export const webDatadogModule = new TypedContainerModule<StaticBindings>((bind) => {
    bind('DatadogService')
        .toDynamicValue((ctx) => {
            const container = ctx.container;

            const { environmentName, commit } = container.get('ConfigService');

            datadogRum.init({
                applicationId: 'eaee0948-8c7b-4d28-b6ad-867daa65eb69',
                clientToken: 'pub3f629456eb0a1285661f0336b1435e72',
                site: 'datadoghq.com',
                service: 'platform-ui',
                env: environmentName,

                // Specify a version number to identify the deployed version of your application in Datadog
                version: commit,
                traceSampleRate: 100,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                defaultPrivacyLevel: 'mask-user-input',
                beforeSend: (event, _context) => {
                    // Do not send RUM errors to Datadog when running locally (regardless of env)
                    if (event.type === 'error' && window.location.hostname === 'localhost') {
                        return false;
                    }

                    return true;
                },
            });

            datadogRum.startSessionReplayRecording();

            datadogLogs.init({
                clientToken: 'pub3f629456eb0a1285661f0336b1435e72',
                env: environmentName,
                site: 'datadoghq.com',
                forwardErrorsToLogs: true,
                sessionSampleRate: 100,
                service: 'platform-ui',
                version: commit,
                allowFallbackToLocalStorage: true,
                beforeSend: (log) => {
                    // Mask any values that are in the MASKED_KEYS array recursively
                    const maskLog = (log: Record<string, unknown>) => {
                        for (const key in log) {
                            if (MASKED_KEYS.includes(key) && typeof log[key] === 'string') {
                                log[key] = '[***]';
                            } else if (typeof log[key] === 'object') {
                                maskLog(log[key] as Record<string, unknown>);
                            }
                        }
                    };

                    maskLog(log);

                    return true;
                },
            });

            return {
                setUserDetails(user?: UserDetails) {
                    if (user) {
                        datadogRum.setUser({
                            id: user.id,
                            name: `${user.firstName} ${user.lastName}`,
                            email: user.email,
                        });
                        datadogLogs.setUser({
                            id: user.id,
                            name: `${user.firstName} ${user.lastName}`,
                            email: user.email,
                        });
                    } else {
                        datadogRum.clearUser();
                        datadogLogs.clearUser();
                    }
                },
                submitErrorToDatadog(error: Error, context?: object) {
                    datadogLogs.logger.error(error.message, { error, details: context, message: error.message });

                    datadogRum.addError(error, context);
                },
                submitActionToDatadog(actionType: string, context?: object) {
                    datadogRum.addAction(actionType, context);
                },
                addFeatureFlagEvaluation(key: string, value: LDEvaluationDetail) {
                    datadogRum.addFeatureFlagEvaluation(key, value);
                },
                logger: datadogLogs.logger,
            };
        })
        .inSingletonScope();
});
