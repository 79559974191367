import { TypedContainerModule } from '@thinkalpha/common/ioc/TypedContainer.js';
import i18next from 'i18next';
import type { StaticBindings } from 'src/types/bindings';

export const i18nModule = new TypedContainerModule<StaticBindings>((bind) => {
    bind('TProvider').toProvider(() => {
        return async () => {
            if (i18next.isInitialized) {
                return i18next.t;
            }

            await new Promise<void>((resolve) => {
                const sub = () => {
                    resolve();
                    i18next.off('initialized', sub);
                };
                i18next.on('initialized', sub);
            });
            return i18next.t;
        };
    });
});
