import type {
    CreateTableByNlpPayload,
    CreateTablePayload,
    NlpTableCreationResult,
    TableCreationResultWithColumns,
} from '@thinkalpha/platform-ws-client/contracts/table.js';
import type { RawClient } from '@thinkalpha/table-client';
import { AuthStatus, ProxyClient } from '@thinkalpha/table-client';
import type { AxiosRequestConfig } from 'axios';
import { filter } from 'rxjs/operators';
import { container } from 'src/StaticContainer';
import { api } from 'src/api';
import { appConfig } from 'src/lib/config';

const client = new ProxyClient(appConfig.tableProxy);

const log = container.get('Logger').getSubLogger({ name: 'table:authentication' });

client.authStatus$.pipe(filter((x) => x === AuthStatus.badToken)).subscribe(() => {
    log.warn({ message: 'table-ws has rejected the user token' });
});

export const usePureProxyClient = () => {
    return client;
};

const exportedClient: RawClient = client;

export { exportedClient as client };

export const setAccessToken = (accessToken: (typeof client)['token']) => {
    log.info({ message: 'About to log in using token', accessToken });
    client.token = accessToken;
};

export const createTable = async (
    payload: CreateTablePayload,
    config?: AxiosRequestConfig,
): Promise<TableCreationResultWithColumns> => {
    return (await api.post(`/tables`, payload, config)).data;
};

// ToDo: Standardize when we just send data and when not in the very near future
export const createTableFromNLP = async (
    payload: CreateTableByNlpPayload,
    config?: AxiosRequestConfig,
): Promise<NlpTableCreationResult> => {
    return (await api.post(`/tables/by-nlp`, payload, config)).data;
};
