import { mapAPIError } from './error';
import { Instant, convert } from '@js-joda/core';
import { enableRemoteSuperuserHeader } from '@thinkalpha/common/contracts/x-headers.js';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import isEqual from 'lodash/isEqual';
import type { Store } from 'redux';
import type { Observable } from 'rxjs';
import {
    ReplaySubject,
    distinctUntilChanged,
    filter,
    firstValueFrom,
    from,
    map,
    of,
    share,
    startWith,
    switchMap,
    timeout,
    timer,
} from 'rxjs';
import { container } from 'src/StaticContainer';
import { appConfig } from 'src/lib/config';
import { rxifyAxios } from 'src/lib/http';
import { replacer, reviver } from 'src/lib/serializer';
import type { AuthState } from 'src/store/reducers/auth';
import type { ClientCredentials } from 'src/store/types';

axiosRetry(axios);

const _log = container.get('Logger').getSubLogger({ name: 'api' });

export const SUPERUSER_ACCESS_EXPIRATION_KEY = 'superuser_access_expiration';

type RootState = { auth: AuthState; ui: { superuserAccessEnabledUntil?: Instant } };
const states$ = new ReplaySubject<Observable<RootState>>(1);
const state$ = states$.pipe(switchMap((stream) => stream));

export function setStoreForApi(storeIn: Store<RootState>) {
    const state$ = from(storeIn);
    states$.next(state$);
}

const accessToken$ = state$.pipe(
    map((x) => x.auth.masqAccessToken ?? x.auth.accessToken ?? x.auth.clientCredentials),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    share({ connector: () => new ReplaySubject(1), resetOnRefCountZero: false }),
);

// the first (and every) time this pipe above emits, it will be a valid unexpired key
const validToken$ = accessToken$.pipe(
    filter((token) => token !== undefined),
    filter((token) => token === null || 'clientId' in token || !token.expires || token.expires.isAfter(Instant.now())),
    // ^^^ token is anonymous || token doesn't expire || token is not expired (expires after right now)
    map((token) => (token ? ('clientId' in token ? token : token.token) : null)),
    timeout(10000),
);

async function getUsableAccessToken(): Promise<string | ClientCredentials | null> {
    return firstValueFrom(validToken$);
}

const isSuperuser$ = state$.pipe(
    map((x) => x.ui.superuserAccessEnabledUntil),
    distinctUntilChanged((a, b) => isEqual(a, b)),
    switchMap((x) =>
        !x || x.isBefore(Instant.now())
            ? of(false)
            : timer(convert(x).toDate()).pipe(
                  map(() => false),
                  startWith(true),
              ),
    ),
    share({ connector: () => new ReplaySubject(1), resetOnRefCountZero: false }),
);

async function getIsSuperuserModeEnabled(): Promise<boolean> {
    return firstValueFrom(isSuperuser$);
}

// Allow storybook to short-circuit the store credentials dependency (which won't be satisified in storybook)
let fixedAuthorizationHeader: { Authorization: string } | undefined;
export function __setStorybookCredentials(credentials: { CLIENT_ID: string; CLIENT_SECRET: string }) {
    fixedAuthorizationHeader = {
        Authorization: `Basic ${btoa(`${credentials.CLIENT_ID}:${credentials.CLIENT_SECRET}`)}`,
    };
}

// Allow storybook to short-circuit the store admin-mode dependency (which won't be satisified in storybook)
let fixedAdminMode: boolean | undefined;
export function __setStorybookAdminMode(enabled: boolean) {
    fixedAdminMode = enabled;
}

type RequestInterceptorFunc = (req: InternalAxiosRequestConfig) => Promise<InternalAxiosRequestConfig>;
type ResponseInterceptorFunc = (req: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;

type RequestInterceptor = [onFulfilled: RequestInterceptorFunc, onRejected?: (e: Error) => Promise<never>];
type ResponseInterceptor = [onFulfilled: ResponseInterceptorFunc, onRejected?: (e: Error) => Promise<never>];

const addAuthorizationHeadersInterceptor: RequestInterceptor = [
    async (req: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
        let authorizationHeader;
        if (fixedAuthorizationHeader) {
            authorizationHeader = fixedAuthorizationHeader;
        } else {
            const token = await getUsableAccessToken();
            if (token) {
                if (typeof token === 'string') {
                    authorizationHeader = {
                        Authorization: `Bearer ${token}`,
                    };
                } else if ('clientId' in token) {
                    req.auth = {
                        username: token.clientId,
                        password: token.clientSecret,
                    };
                }
            }
        }

        if (!req.headers.has('Content-Type')) req.headers['Content-Type'] = 'application/json';
        for (const header in authorizationHeader) {
            // don't overwrite the Authorization header if it already exists
            if (req.headers[header]) continue;
            req.headers[header] = (authorizationHeader as any)[header];
        }

        return req;
    },
];

const addHeaderAdminInterceptor: RequestInterceptor = [
    async (req: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
        let isSuperuserPrivilegeEnabled = false;
        if (fixedAdminMode !== undefined) {
            isSuperuserPrivilegeEnabled = fixedAdminMode;
        } else if (await getIsSuperuserModeEnabled()) {
            isSuperuserPrivilegeEnabled = true;
        }

        req.headers[enableRemoteSuperuserHeader] = isSuperuserPrivilegeEnabled.toString();

        return req;
    },
];

const isAxiosError = (err: Error): err is AxiosError => (err as any).isAxiosError === true;

const detectUnauthorizedInterceptor: ResponseInterceptor = [
    async (res: AxiosResponse): Promise<AxiosResponse> => {
        if (res.status === 401) {
            // todo: check if the token is actually invalid by calling userinfo, and if its not, ignore this
        }
        return {
            ...res,
        };
    },
    async (err: Error) => {
        if (isAxiosError(err) && err.response?.status === 401) {
            // todo: check if the token is actually invalid by calling userinfo, and if its not, ignore this
        }
        throw err;
    },
];

export function createInstance(config?: AxiosRequestConfig): AxiosInstance {
    const inst = axios.create({
        baseURL: appConfig.api,
        ...config,
        transformRequest: [
            (payload, headers) => {
                if (typeof payload === 'object') {
                    try {
                        const json = JSON.stringify(payload, replacer, 2);
                        headers['Content-Type'] = 'application/json';
                        return json;
                    } catch (e) {
                        return e;
                    }
                } else {
                    return payload;
                }
            },
            ...(!config?.transformRequest
                ? []
                : Array.isArray(config.transformRequest)
                  ? config.transformRequest
                  : [config.transformRequest]),
        ],
        transformResponse: [
            (resp) => {
                return resp && JSON.parse(resp, reviver);
            },
            ...(!config?.transformResponse
                ? []
                : Array.isArray(config.transformResponse)
                  ? config.transformResponse
                  : [config.transformResponse]),
        ],
        paramsSerializer: { indexes: false },
    });

    inst.interceptors.request.use(...addAuthorizationHeadersInterceptor);
    inst.interceptors.request.use(...addHeaderAdminInterceptor);
    inst.interceptors.response.use(...detectUnauthorizedInterceptor);

    return inst;
}

const inst = createInstance();
const rxInst = rxifyAxios(inst, mapAPIError());

export { inst as api, rxInst as rxapi };
