import { Type, type Static } from '@sinclair/typebox';

export const FusionAuthConfigSchema = Type.Object({
    callbackURL: Type.String(),
    fusionAuthApplicationID: Type.String(),
    fusionAuthTenantID: Type.String(),
    fusionAuthURL: Type.String(),
    loggedOutURL: Type.String(),
    tokenRevocationURL: Type.String(),
    type: Type.Literal('fusionAuth'),
    userInfoURL: Type.String(),
});

export const KeycloakConfigSchema = Type.Object({
    applicationID: Type.String(),
    callbackURL: Type.String(),
    keycloakURL: Type.String(),
    loggedOutURL: Type.String(),
    logoutURL: Type.String(),
    tenantID: Type.String(),
    tokenRevocationURL: Type.String(),
    type: Type.Literal('keycloak'),
    userInfoURL: Type.String(),
});

export const OAuth2ConfigSchema = Type.Union([FusionAuthConfigSchema, KeycloakConfigSchema]);

export type FusionAuthConfig = Static<typeof FusionAuthConfigSchema>;
export type KeycloakConfig = Static<typeof KeycloakConfigSchema>;
export type OAuth2Config = Static<typeof OAuth2ConfigSchema>;
